//src/scss/variables.scss


// Color Variables
$primary: #007aff; // iMessage Blue (represents sent messages and primary action elements)
$secondary: #e5e5ea; // Light Gray for background accents (similar to iMessage bubbles)
$background-color: #ffffff; // Pure White (for main background)
$text-color: #1c1c1e; // Dark Gray (for readable text)
$footer-bg-color: #f2f2f7; // Light Gray (for footer, similar to message list backgrounds)
$footer-border-color: $secondary; // Border color matching light gray

// Spacing Variables
$padding-small: 8px;
$padding-medium: 16px;
$padding-large: 24px;

// Font Variables
$font-family: 'Arial, sans-serif';

// Font size variables
$font-size-small: 0.9rem;
$font-size-large: 1.5rem;

// Other Design Tokens (add more as needed)
