//src/scss/footer.scss

// Import Variables
@import './variables';

.footer {
  background-color: $background-color; // Keep the same background color as the header
  color: $primary; // Set the text color to the dark blue primary color
  padding: $padding-medium;
  border-top: 4px solid $primary;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.2); // Adds a shadow below the border  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-text {
  font-size: $font-size-small;
  margin-bottom: $padding-small;
  color: $text-color; // Ensure text color is dark blue
}

.footer-links {
  display: flex;
  gap: $padding-small;
  flex-wrap: wrap;
  justify-content: center;

  .footer-link {
    color: $text-color; // Set link color to dark blue
    padding: 0 $padding-small;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: $secondary; // Use secondary color for hover effect
      text-decoration: underline;
    }
  }
}
