// Import Variables
@import '../scss/variables';

.home-page {
  background-color: $background-color;
  color: $text-color;
  text-align: center;

  h1 {
    color: $primary;
    font-weight: bold;
  }

  .lead {
    font-size: 1.25rem;
    color: lighten($text-color, 10%);
  }

  .btn-custom {
    background-color: $primary;
    border-color: $primary;
    color: #fff;

    &:hover {
      background-color: lighten($primary, 10%);
      border-color: lighten($primary, 10%);
    }
  }
}
