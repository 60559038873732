//src/scss/timeline.scss

// Import Variables
@import '../scss/variables';

.timeline-container {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;
  padding: $padding-large 0;
}

.timeline-item-container {
  position: relative;
  width: 50%;
  padding: $padding-medium;
}

.left {
  left: 0;
}

.right {
  left: 50%;
}

.timeline-item {
  background-color: #ffffff;
  padding: $padding-medium;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: $padding-large;
}

.timeline-marker {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  border-radius: 50%;
  color: #fff;
  z-index: 2;
}

.align-left {
  left: 103.5%;
  transform: translate(-50%, -50%);
}

.align-right {
  right: 103%;
  transform: translate(50%, -50%);
}

.timeline-content {
  padding: $padding-medium;
}

.timeline-year {
  font-size: $font-size-small;
  color: $text-color;
  font-weight: bold;
  margin-bottom: $padding-small;
}

.timeline-line {
  position: absolute;
  width: 4px;
  background-color: $primary;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -2px;
}
