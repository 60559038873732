// src/scss/custom.scss

// Import Variables
@import './variables';

// Import Bootstrap
@import "~bootstrap/scss/bootstrap";

// Global Styles
body {
  font-family: $font-family;
  background-color: $background-color;
  color: $text-color;
}
