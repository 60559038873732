// src/scss/header.scss

// Import Variables
@import './variables';

.navbar {
  background-color: $background-color; // Using the background color variable for consistency
  border-bottom: 4px solid $primary;
  font-size: $font-size-large !important;
}

.navbar-brand {
  font-weight: bold;
  font-size: $font-size-large * 1.2 !important; // Increase size by 20% of your defined large font size variable
}

.nav-link {
  color: $text-color; // Using the text color variable
  padding: $padding-small; // Adding padding for consistent spacing
  text-decoration: none;
  transition: color 0.3s ease; // Smooth transition for hover effects
  
  &:hover {
    color: $secondary; // Use the secondary color for hover effect
  }
}
