//src/scss/layout.scss

// Import Variables
@import './variables';

.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
  padding: $padding-medium; // Use variable for padding
  background-color: lighten($background-color, 5%); // Slightly different background using function
}


